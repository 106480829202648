<script lang="ts" setup>
// @ts-expect-error JS library
import { useMq } from 'vue3-mq'
import { storeToRefs } from 'pinia'
import { vScroll } from '@vueuse/components'
import type { UseScrollReturn } from '@vueuse/core'
import { useGtag } from 'vue-gtag-next'
import { useThemeOptions } from '~/composables/theme'
import { useLocaleOptions, useLocaleThemePath } from '~/composables/locale'
import { useRadar } from '~/store/radar'
import type { ListItem } from '~/types/custom'
import { useRoot } from '~/store'
import { LordIconSource, LordIconTrigger, MDIIcon } from '~/types/assets'
import type { Icon, LordIcon } from '~/types/assets'

const root = useRoot()

const { themeOption, themeOptions, onThemeSwitch } = useThemeOptions()

const { localeOption, localeOptions, onLocaleSwitch } = useLocaleOptions()

const { wheelsCounter } = storeToRefs(root)

const { route, previousRoutePath } = storeToRefs(useRadar())

const scrollRoot = ref<HTMLElement | null>(null)

const { xs, sm } = useMq()
const { t } = useI18n()
const scrollerActive: Ref<boolean> = ref(true)
const { y } = useScroll(scrollRoot, { behavior: 'smooth' })
const { height } = useWindowSize()
const { event } = useGtag()

const socialIcons: Array<Icon & { title: string; ariaLabel: string }> = [
  {
    src: MDIIcon.github,
    name: 'GitHub',
    url: 'https://github.com/LorenzoRottigni/',
    tooltip: 'GitHub | Lorenzo Rottigni',
    title: t('footer.socials.anchors.github.title'),
    ariaLabel: t('footer.socials.anchors.github.aria-label')
  },
  {
    src: MDIIcon.gitlab,
    name: 'GitLab',
    url: 'https://gitlab.com/LorenzoRottigni/',
    tooltip: 'GitLab | Lorenzo Rottigni',
    title: t('footer.socials.anchors.gitlab.title'),
    ariaLabel: t('footer.socials.anchors.gitlab.aria-label')
  },
  {
    src: MDIIcon.linkedin,
    name: 'LinkedIn',
    url: 'https://www.linkedin.com/in/lorenzo-rottigni-ba402b222/',
    tooltip: 'LinkedIn | Lorenzo Rottigni',
    title: t('footer.socials.anchors.linkedin.title'),
    ariaLabel: t('footer.socials.anchors.linkedin.aria-label')
  },
  {
    src: MDIIcon.instagram,
    name: 'Instagram',
    url: 'https://www.instagram.com/lorenzorottigni/',
    tooltip: 'Instagram | Lorenzo Rottigni',
    title: t('footer.socials.anchors.instagram.title'),
    ariaLabel: t('footer.socials.anchors.instagram.aria-label')
  }
]

const downIcon: LordIcon = {
  src: LordIconSource?.chevronDown,
  trigger: LordIconTrigger?.loop,
  revert: true,
  delay: 500,
  size: 30
}

const iconExit: LordIcon = {
  src: LordIconSource.exitFire,
  trigger: LordIconTrigger.loop,
  revert: true,
  delay: 4000,
  size: 45,
  minimal: false
}

const iconBack: LordIcon = {
  src: LordIconSource.keyLeft,
  trigger: LordIconTrigger.loop,
  revert: true,
  delay: 4000,
  size: 45,
  minimal: false
}

const iconLove: LordIcon = {
  src: LordIconSource.heart,
  trigger: LordIconTrigger.loop,
  revert: false,
  delay: 4000,
  size: 35,
  minimal: false
}

function onScroll(e: UseScrollReturn) {
  if (scrollRoot.value?.clientHeight && !xs && !sm) {
    const half = scrollRoot.value?.clientHeight / 2
    const overscroll = e.y.value % scrollRoot.value?.clientHeight
    let offset = Math.trunc(e.y.value / scrollRoot.value?.clientHeight)

    offset += overscroll > half ? 1 : 0

    if (!e.isScrolling.value) {
      y.value = offset * (scrollRoot.value?.clientHeight + 8)
      scrollerActive.value = !(!!scrollRoot.value?.scrollHeight && offset + 1 >= wheelsCounter.value)
    }

    root.syncScroll(e)
  }
}

function scrollSection() {
  const activeOffset = Math.trunc(y.value / height.value)
  const scrollValue = (activeOffset + 1) * height.value
  y.value = scrollValue

  event('scroll_section', {
    event_category: 'layout',
    event_label: `section_show_${activeOffset}`
  })
}
</script>

<template lang="pug">
#root
  .layout__show(
    ref="scrollRoot"
    v-scroll="onScroll"
  )
    .layout__show__wheel(
      v-for="number in wheelsCounter"
      :key="`circle-${number}`"
    )
      .layout__show__wheel__outer
        span
        .layout__show__wheel__inner
    header
      nav
        .header__navigator
          .header__exit
            NuxtLink(
              :to="useLocaleThemePath('/')"
              :title="$t('layout.anchors.home.title')"
              :aria-label="$t('layout.anchors.home.aria-label')"
            )
              IconLord(v-bind="iconExit")
              span {{ $t('layout.exit') }}
          .header__back
            NuxtLink(
              :to="\
                (!!previousRoutePath !== route?.path) \
                ? useLocaleThemePath(previousRoutePath) \
                : '/'\
              "
              :title="$t('layout.anchors.back.title')"
              :aria-label="$t('layout.anchors.back.aria-label')"
            )
              IconLord(v-bind="iconBack")
              span {{ $t('layout.anchors.back.label') }}
        .header__controllers
          .header__locales
            UIDropdown(
              :options="localeOptions",
              :option="localeOption",
              list-type="locale"
              @update:option="(theme: ListItem)  => onLocaleSwitch(item?.title || item?.textContent || 'unknown')"
            )
          .header__themes
            UIDropdown(
              :options="themeOptions",
              :option="themeOption",
              list-type="theme"
              @update:option="(theme: ListItem)  => onThemeSwitch(item?.title || item?.textContent || 'unknown')"
            )
    main
      .container-fluid
        .row
          div(class="col-12 lg:col-10 lg:offset-1 xl:col-8 xl:offset-2 h-full px-0")
            RouterView(v-slot="{ Component }")
              Transition(
                name="fade-down"
                mode="out-in"
              )
                Component(
                  :is="Component",
                  :key="$route.fullPath"
                )
    .layout__show__socials
      ul
        li(
          v-for="(icon, index) in socialIcons"
          :key="`social-icon${index}`"
          v-tooltip="icon.tooltip"
        )
          a(
            :href="icon.url",
            target="_blank"
            rel="nofollow"
            :title="icon.title"
            :aria-label="icon.ariaLabel"
          )
            UnoIcon.text-xl(:class="icon.src")
    footer
      .footer__sign
        p
          | {{ $t('footer.text-content[0]') }}
          IconLord(v-bind="iconLove")
          | {{ $t('footer.text-content[1]') }}
      Transition(name="fade-bottom")
        span.scroll-down(
          v-if="\
            scrollerActive && \
            (scrollRoot?.scrollHeight ? scrollRoot.scrollHeight > height : false) \
            && !xs && !sm \
          "
          @click="scrollSection"
        ) {{ $t('layout.scroll-down') }}
          IconLord(v-bind="downIcon")
      LayoutRights
      NuxtLink.logo(
        :to="useLocaleThemePath('/')"
        :title="$t('layout.anchors.home.title')"
        :aria-label="$t('layout.anchors.home.title')"
      )
        IconLogo(
          :size="125"
          :line-drawing="true"
        )
</template>

<style lang="sass" scoped>
#root
  @apply w-full h-full bg-dynamic-dark
  .layout__show
    @apply w-full bg-circular bg-center overflow-y-auto overflow-x-hidden h-[100dvh] scrollbar-primary relative border-4 border-primary
  header
    @apply w-full fixed top-0 left-0 z-50
    nav
      @apply w-full flex justify-center lg:justify-between items-start p-1
      .header__navigator
        @apply w-full max-w-[500px] lg:w-[375px] bg-dark-800 flex border-b-4 border-r-2 border-double border-primary
        .header__exit
          @apply w-full bg-black
          a
            @apply flex-center text-primary
            span
              @apply whitespace-nowrap
        .header__back
          @apply w-full
          a
            @apply flex-center text-primary
      .header__controllers
        @apply hidden lg:flex gap-6 pt-3 pr-6
  main
    @apply w-full h-auto
    .container-fluid
      @apply w-full flex-center overflow-x-hidden px-0
      .row
        @apply w-full
  .layout__show__socials
    @apply hidden lg:block fixed left-0 top-[50%] translate-y-[-50%] bg-transparent-dark border border-primary z-50 rounded-r-2xl overflow-hidden
    ul
      @apply flex-center flex-col p-2
      li
        @apply p-1.5 text-primary
        &:not(:first-of-type)
          @apply border-t border-black
        &:first-of-type
          @apply pt-0
        &:last-of-type
          @apply pb-0
  footer
    .footer__sign
      @apply fixed left-0 bottom-0 z-50 w-[375px]
      p
        @apply w-full flex items-center justify-center bg-dark-900 border-t-4 border-double border-primary py-2 text-white
        clip-path: polygon(0 0, 95% 0, 100% 100%, 0% 100%)
    span.scroll-down
      @apply w-fit fixed bottom-0 left-[50%] translate-x-[-50%] cursor-pointer text-primary flex-center flex-col underline bg-dark-900 px-8 pb-3 border-t-8 border-double border-primary hover:bg-dark-800 z-50
      clip-path: polygon(10% 0, 90% 0, 100% 100%, 0 100%)
    .logo
      @apply fixed bottom-4 right-4 hidden lg:block

  .layout__show__wheel
    @apply hidden lg:block border-4 border-secondary rounded-[50%] h-[100dvh] w-[100dvh] absolute left-[50%] translate-x-[-50%] scale-[1.4] xl:scale-[1.6] overflow-hidden opacity-60
    .layout__show__wheel__outer
      @apply w-full h-full relative animate-spin-wheel
      &::after,
      &::before
        content: ''
        @apply w-8 h-20 absolute top-[50%] translate-y-[-50%] bg-secondary z-[100]
      &::after
        @apply left-0 -ml-1
        clip-path: polygon(0 0, 100% 10%, 100% 90%, 0 100%)
      &::before
        @apply right-0 -mr-1
        clip-path: polygon(0 10%, 100% 0, 100% 100%, 0 90%)
      span
        &::after,
        &::before
          content: ''
          @apply w-28 h-6 absolute left-[50%] translate-x-[-50%] bg-secondary z-[100]
        &::after
          @apply top-0 -mt-1
          clip-path: polygon(0 0, 100% 0, 90% 100%, 10% 100%)
        &::before
          @apply bottom-0 -mb-1
          clip-path: polygon(10% 0, 90% 0, 100% 100%, 0 100%)
      .layout__show__wheel__inner
        @apply absolute top-8 left-8 right-8 bottom-8 border-4 border-dashed rounded-[50%] border-secondary z-[100]
    &:nth-child(2)
      @apply border-primary
      transform: translate(-50%, 100%)
      .layout__show__wheel__outer
        @apply animate-spin-wheel-alt
        &::after,
        &::before,
        span::before,
        span::after
          @apply bg-primary
        .layout__show__wheel__inner
          @apply border-primary
    &:nth-child(3)
      transform: translate(-50%, 200%) scale(1.4)
    &:nth-child(4)
      @apply border-white
      transform: translate(-50%, 300%) scale(1.4)
      .layout__show__wheel__outer
        @apply animate-spin-wheel-alt
        &::after,
        &::before,
        span::before,
        span::after
          @apply bg-white
        .layout__show__wheel__inner
          @apply border-white
</style>
